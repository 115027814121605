<template>
  <main-layout class="main-layout">
    <section class="section" id="hero">
      <span class="shape shape--1"></span>
      <span class="shape shape--2"></span>
      <div class="container">
        <div class="is-centered columns">
          <div class="container-laptop is-7 column">
            <img v-lazy="`$/images/home/extension_demo.png`" :alt="$t('home.relax')" />
          </div>
          <div class="is-5 column" style="display:flex;align-items:center;justify-content:center">
            <div class="has-text-centered-mobile info-wrap">
              <h1 class="title is-size-1-desktop">
                <span class="has-text-weight-bold is-block" v-t="`home.relax`"></span>
                <span class="has-text-weight-light" v-t="`home.what-we-do`"></span>
              </h1>

              <p
                class="
                  has-text-weight-normal has-text-grey
                  is-size-6-mobile is-size-5-desktop
                "
                v-t="`cilp.use-pouch`"
                style="margin-bottom: 24px"
              ></p>

              <utm-itm-link :targetUrl="accountJoinUrl" class="add-button" v-only-mobile>
                Join for Free
              </utm-itm-link>

              <add-to-browser medium bold position="Homepage" class="add-button" v-only-desktop> Add Pouch {{ browser }} – It's FREE </add-to-browser>

              <div class="rating-container">
                <rating size="7" />
                <span class="rating-container__info">
                  <span v-t="`cilp.trusted`"></span>
                  <b v-t="`cilp.shoppers`"></b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="bg-image">
      <img v-lazy="`$/images/home/HomePage_Wave_RelaxBottom.svg`" />
    </div>

    <div class="cards-section section">
      <cards></cards>
    </div>

    <how-it-works></how-it-works>

    <div class="bg-image">
      <img v-lazy="`$/images/home/HomePage_Wave_RetailersTop.svg`" />
    </div>

    <section-wrap
      title="OUR RETAILERS"
      subtitle="Save on over +3000 UK stores."
      description="We negotiate market leading offers for our community.<br/> Here are our top picks!"
      class="retailers"
    >
      <carousel
        :loop="true"
        :perPageCustom="[
          [320, 2],
          [768, 4]
        ]"
        :centerMode="true"
        paginationColor="#DDDDDD"
        paginationActiveColor="#FF6441"
        :autoplay="true"
        class="retailers__list"
      >
        <slide v-for="(retailer, i) in retailers" :key="i">
          <div class="retailers__column">
            <a target="_blank" :href="buildClickoutLink(item)" class="retailers__card" v-for="(item, j) in retailer" :key="j">
              <img v-lazy="`${item.logo}`" alt="" />
            </a>
          </div>
        </slide>
      </carousel>
    </section-wrap>

    <div class="bg-image">
      <img v-lazy="`$/images/home/HomePage_Wave_RetailersBottom.svg`" />
    </div>

    <div class="reward">
      <div class="container">
        <div class="columns">
          <div class="intro column is-7-desktop">
            <span class="intro__subtitle">POUCH POINTS</span>
            <h2 class="intro__title">We reward you for shopping!</h2>
            <p class="intro__desc">
              Join the Pouch family and <span class="intro__desc--bold">earn Pouch Points</span> by shopping at participating retailers, completing
              certain tasks and referring your friends.
            </p>
            <p class="intro__desc">
              <span class="intro__desc--bold">Redeem your points for Gift Cards</span> from your favourite retailers!
              <br />
              <router-link :to="{ name: 'tandcs' }">T&amp;Cs</router-link> apply.
            </p>
            <div class="reward__cta reward__cta--only-desktop">
              <utm-itm-link class="button is-primary has-text-weight-bold" :targetUrl="accountJoinUrl">
                Join &amp; Earn
              </utm-itm-link>
              <router-link class="button has-text-primary has-text-weight-bold reward__learn-more-button" :to="{ name: 'points' }"
                >Learn more</router-link
              >
            </div>
          </div>
          <div class="reward__img column is-5-desktop">
            <img v-lazy="`$/images/home/gift_cards_amazon.png`" alt="pouch points" />
          </div>
        </div>
      </div>
      <div class="reward__cta reward__cta--only-mobile">
        <utm-itm-link class="button has-text-weight-bold is-primary" :targetUrl="accountJoinUrl">
          Join &amp; Earn
        </utm-itm-link>
        <router-link class="button has-text-primary has-text-weight-bold reward__learn-more-button" :to="{ name: 'points' }">Learn more</router-link>
      </div>
    </div>

    <div class="bg-image">
      <img v-lazy="`$/images/home/HomePage_Wave_PublishersTop.svg`" />
    </div>

    <section-wrap title="WE’RE LOVED" subtitle="Featured in BBC Dragon’s Den and loads more." class="section is-medium" id="press">
      <div class="container">
        <div class="columns is-mobile is-multiline is-gapless has-text-centered">
          <div class="column is-3-tablet is-6-mobile" v-for="(publication, key) in featuredPublications" :key="key">
            <img v-lazy="publication.logo" :alt="publication.name" style="max-width: 68%" />
          </div>
        </div>
      </div>
    </section-wrap>
    <div class="bg-image">
      <img v-lazy="`$/images/home/HomePage_Wave_PublishersBottom.svg`" />
    </div>
    <section-wrap
      title="EXCLUSIVE DISCOUNTS"
      subtitle="Join our Community<br />and get the best discounts."
      description="Stop overpaying for the things you want the most. <br />Get the best price. Every time."
      class="section"
      id="testimonials"
    >
      <testimonials></testimonials>
    </section-wrap>

    <section-wrap class="add-pouch">
      <div class="add-pouch__header">
        <span class="add-pouch__title">Relax.</span>
        <h2 class="add-pouch__subtitle">We do the voucher search for you.</h2>
      </div>

      <utm-itm-link :targetUrl="accountJoinUrl" class="add-button" v-only-mobile>
        Join for Free
      </utm-itm-link>

      <add-to-browser medium bold position="Homepage" class="add-button" v-only-desktop> Add Pouch {{ browser }} – It's FREE </add-to-browser>

      <div class="rating-container">
        <rating size="7" />
        <span class="rating-container__info">
          <span v-t="`cilp.trusted`"></span>
          <b v-t="`cilp.shoppers`"></b>
        </span>
      </div>
    </section-wrap>

    <proposition-modal :active="propositionModalActive" :part="propositionModalPart" @close="propositionModalActive = false"></proposition-modal>
  </main-layout>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import AddToBrowser from '@/views/partials/AddToBrowser'
import Cards from '@/views/partials/Cards'
import PropositionModal from '@/views/partials/PropositionModal'
import UtmItmLink from '@/views/partials/UtmItmLink.vue'

import Rating from '@/views/components/Rating'
import Testimonials from '@/views/components/Testimonials'

import HowItWorks from './components/HowItWorks'
import SectionWrap from './components/SectionWrap'
import config from '@/config'

import { buildClickoutLink } from '@/core/services/Analytics'

const GOOGLE_VENDOR_NAME = 'Google Inc.'

function isChromium () {
  return Boolean(window.chrome)
}

export default {
  components: { AddToBrowser, Testimonials, PropositionModal, Rating, Cards, HowItWorks, SectionWrap, UtmItmLink },

  data: () => ({
    propositionModalActive: false,
    propositionModalPart: null,
    consentInfoActive: false,
    retailers: [
      [
        {
          logo: '$/images/home/Retailers/05_AO_RetailerHomePage.png',
          link: 'https://www.awin1.com/cread.php?awinmid=19526&awinaffid=272501&clickref=A2ASUBID',
          encryptedTrackingLink: 'SlVoMTJsWVRXcDhtek9MUXZibGIyRTRtWXpUbHltYlR0ZFVNWUZrVEpXU2p3bVRXbkVSbmM9MnpHbE1Ra1RhWWpSVlhtTm5OZGg1WTJFWFZ5RFgxdGJseXpGanBIV1ppR0VNODFZaDFiaHdEY0dORGppOUpWM1dGclp4cGphTUp0eVZMYmx2enhSb0VhSlpkY1E5MFVRVllKTlp6V0JoVFVKdWpRWjBkbFRkSkpoTnhjZDNkT21jUmJUTGtUakVENDhMZFFUdUNZSVlHUGFzQnpOdzFNMVdOR1ZhaUd4bUdObWJqY0FtMU1kVE1aSndS01',
          id: '2643'
        },
        {
          logo: '$/images/home/Retailers/01_eBay_RetailerHomePage.png',
          link: 'http://www.ebay.co.uk/?mkcid=1&mkrid=710-53481-19255-0&siteid=3&campid=5338662692&customid=A2ASUBID&toolid=10001&mkevt=1',
          encryptedTrackingLink: 'd2pOVDZTaGF6VWxaakZ6elkxQTEwTGJ5UUo5c3pXWndNTjBFWk0xaFVORk1SL1QwaFZweHpRR3BRSG1CUk9kbUZUVkRwaVpiOVlSbXprcGVaaHpZWkhKTXRHVFRtek0xOThkMFZqU1k0dE1QbU5sME9kej1RRVRXQ3dQMU5hV3pZTXZXYlBJMjl4U2RMTldrMlRqQWtUV0VQdFUxYzVWTFQxTW1RaWtPRWpIZHRqeDNKRGFaak5ORVl5WVdpTkhweTJRTERaendVcmQ101',
          id: '2642'
        },
        {
          logo: '$/images/home/Retailers/09_Samsung_RetailerHomePage.png',
          link: 'https://www.awin1.com/cread.php?awinmid=17946&awinaffid=272501&clickref=A2ASUBID',
          encryptedTrackingLink: 'V1ZrWTFZVFJtSm0yMjl5TkY0bXV6aW1NV0o5UVdSVnIyV2pkVGpZSlZUNUoxdTN2Tk5Sa0UxV1YweFRMbjE2MlliNFcxUURkTmxtbnR6UUlocEJKTmtweGp1WTkyRmNva1JtVUJSVGRjUmhFSlVaMmgxWVdhOT1FT1F4T2k0NWMzWmF1WVhKenpUSlBrMzNjek04UjFIR1hkQTltY21tbFJWTU5YVUZNcE5tSDEwTlJkbVlMamlwYzl3UnVyd3pYYmRrbW45YlhWYnBNYWxZaUpqQlFaYmIwZE1ad3puTXljalNJWGRqbDNjQjhOMHlMemRRTQ--01',
          id: '2627'
        }
      ],
      [
        {
          logo: '$/images/home/Retailers/06_Sephore_RetailerHomePage.png',
          link: 'https://www.awin1.com/cread.php?awinmid=22773&awinaffid=272501&clickref=A2ASUBID',
          id: '2735',
          encryptedTrackingLink: 'Ukp6dW5uajNaTUlwTE1DM1IwUDNJVFhTM3RqaldqeXo9d0QyTHhiUU1HWkUyRXpXM1lVaWtWVG1aMjE5VGRHTllkU1l6VkZORUwyOVpab3hRSk1pdz1OVEZ6Wm1iMGNZMWFaTjJhUVdEbXdGbDNka1Z1T1lraGxZRTI1RmNOT3A2TjVZbERJNFoxc2NHVWpUTmNKa1F0Mnc-01'
        },
        {
          logo: '$/images/home/Retailers/02_Adidas_RetailerHomePage.png',
          link: 'https://adidas.prf.hn/click/camref:1101l8Muh/pubref:A2ASUBID',
          encryptedTrackingLink: 'ZE9sVkdwME1ueGt5a0FXSlRiWnhsSDFUbEdkakVZR2I0empDWFVhWXViTk52dEV6bEpjTUlNUkxjenlkV2hobUpURjNGTlZHVUxRM2FkY2JSUU5rMjJjeU9VWGxpbUxXTmJFOTIzdnY9WXBkWVk1eXpZV2pqRkhCVm8xMldKWWFuYXd5R1RsRVZXdVd5ZExkWm1YNU12eG1Pa0xab2h6TkRNR1NBNVJ6QVZSMHBWWlcwUllHSHp3YU5wSkoxbHlaNkVNMDNCdlFPYVpUQk56bVoxQ1k-01',
          id: '2584'
        },
        {
          logo: '$/images/home/Retailers/10_Farfetch_RetailerHomePage.png',
          link: 'https://prf.hn/click/camref:1011liPCF/pubref:A2ASUBID',
          encryptedTrackingLink: 'emNSYnQzVlp5elZrWVdJdWNuSlUwWWtRemJ6dFhsY1lNQW1aPXlXd1lVM29qTm1OQm9ZOU1OdVZNY25oNUc9MDlaRDVaYXZZVTMwMk53SjFVWldoU1RHejhEZGhVRkVIYkVXeURPMVFDdGlrbG5SRmlseW1WdWJaak5KRm0zQWoyNmVXeFp3dmRETlpWempKVlJOQmkyaDJGYnBIUVdiaTlSTzRvTWRteDZoVXlUeFhzR1JhPURkM21TTWptWVZrbHBkNEd5Y1VSaGpiWVJoU3dteGRHVkNNY3ZNM1oxNmFZUUNXd1EyWk0xbFdZb1VNME5WdmFKTWNSUTJpcFdjMzV2ZEc-01',
          id: '3126'
        }
      ],
      [
        {
          logo: '$/images/home/Retailers/07_Groupon_RetailerHomePage.png',
          link: 'http://t.groupon.co.uk/r?tsToken=UK_AFF_0_211549_1699299_0&sid=A2ASUBID&url=https%3A%2F%2Fwww.groupon.co.uk%2F',
          encryptedTrackingLink: 'bjlVeUpQRXZVenUxTk9Mc2p6all4ejVSMUdVMnhWZHZsd0xZVk5YazAydFJkTWIwWHZieWtNVGRuUVFrUzU5MDBuTjNJUXdJV1g4TkxCYXJSMURDSGR6TVlWdWR6bFk0aFZVWk84VU9MYnRXV1lkSmkzMVpkWFFONE1Za1FCek9HSW5VMT1kdmpNalZESGNTMVpFVFYyalZFeUZjOEpaPUpEbHlZSlRQQnpYaDVvWU92a1lmOW11d1pKVEZvTVIy01',
          id: '2599'
        },
        {
          logo: '$/images/home/Retailers/03_NordVPN_RetailerHomePage.png',
          link: 'https://nordvpn.sjv.io/c/417474/493823/7452?subId1=A2ASUBID',
          encryptedTrackingLink: 'TE5Ea1F1azBJd005a1QzNTRkaU52SnpNVVFRRE5NeDBsM1ZWQzF6WXowTE45azNPM09oPTFaZDJRWUVqTm1KRHZ6WVJaWjFOTXhHak16WVU4RmM4UkRtV0ZaVFVpRTJRYXpZTkV5Y3VMMm9Md1djPXdOakNCZE42TzlHeQ--01',
          id: '3256'
        },
        {
          logo: '$/images/home/Retailers/11_H_B_RetailerHomePage.png',
          link: 'https://prf.hn/click/camref:1100l4dcq/pubref:A2ASUBID',
          encryptedTrackingLink: 'NWRuMkd6UldaYnZ5TUU1TFZPWlZHQkpjWmhWbVZGRzJkYWpjR0p5VVI2Wms4Wk1YTWtraEd6Yj1RbWxCTjVrVTNsV0ZjR2Ryakphd016SFdUWjFSTldqd1lvd2JGPTNrWnhXUmR6WmlaMTBHeTFqYllzTjRjY2hhbXptWU0zbThsWXUwUnk5RzVZWmxYdWptSk52T1dUM0ZaNUpEMk1TWWQwRUc1TWxjYzBPejBaVk5NeU16eVFtVlNBNXczUkdHVXhTVlJPOHZpdDFOR25PV3pMaXJhU0pSWkZ2Rm9FWWF5UXpKenlCaWFZRHdSZGp6bWgyd2ltRzg-01',
          id: '2607'
        }
      ],
      [
        {
          logo: '$/images/home/Retailers/08_Asos_RetailerHomePage.png',
          link: 'https://www.awin1.com/cread.php?awinmid=5678&awinaffid=272501&clickref=A2ASUBID'
        },
        {
          logo: '$/images/home/Retailers/04_SportsDirect_RetailerHomePage.png',
          link: 'https://click.linksynergy.com/deeplink?id=jc5PrZcv/2Y&mid=45657&u1=A2ASUBID&murl=https%3A%2F%2Fwww.sportsdirect.com%2F',
          encryptedTrackingLink: 'emppTTBFR2phbFFKbjF6U2EwZDBtYXZHMmxFTmNWTUdIWVdOd1ZrWGRsWlkwY05XMlk4bFlhamFibVlzT3piYkdKekxKV1c5VDlPVVNXY3Y1TDJWMW1HMmVOcFRsVW16WldZeTNGeTJMdD1abHl6aUo4NDFzYVdWa3VjVjJ0eWNIajJXNGREYmR6ZUdqUkRoRWM5TVZkVTBqY2NsV2F5a1F0SE5tRzFKUFpGYlFaPXlrTVJabVpHREVoU3laeFRXak5EeVBVdFhFVnNwOE5CeEZ4Q3paUk53QkRweU1OYzB0RmFRYlk1aw--01',
          id: '2635'
        },
        {
          logo: '$/images/home/Retailers/12_Tui_RetailerHomePage.png',
          link: 'https://tui-uk.7cnq.net/c/417474/659542/10316?subId1=A2ASUBID'
        }
      ],
      [
        {
          logo: '$/images/home/Retailers/13_LaRedoute_RetailerHomePage.png',
          link: 'https://www.awin1.com/cread.php?awinmid=1496&awinaffid=272501&clickref=A2ASUBID',
          encryptedTrackingLink: 'NFBVT0J6VVRXYmhEak9VYjFoU25OaGxqTlVOVG1VMEw9VHd5bVUwR0dZVU53M1ZrSE1ReXdseW5RTnhGV0RhazB6R1psbHdVQXViVT1qekh5YzJqY053Wk5GWVljY0cyWWxtWVNtMHpMR2psZDhGWmQyemFzV0VaTUJZTkpuMm41elFVbVJ6YlF5bVR6WTBOSmRWU1JsR3Y1YUI4UVF4dWFZMnlPRXdXTGg0eWRkTFJhejNNM012d0pBbHpSY0VQU0ZQWnh5M0xnRU9ZUUpFU1dUa2NkVVZPbUdSR3djOTRSeWNoQ1pNSnlqVVpYVmNkYUdabWxpRm5oWk1OY21zVmRSYTJiaDFNRTlZMzF4TVRXblJJeFZsM2hNMGpaMXp1bVk0dmMybDI9VEdj01',
          id: '2622'
        },
        {
          logo: '$/images/home/Retailers/17_Virgin_RetailerHomePage.png',
          link: 'https://virgin-experience-days.ldaz.net/c/417474/615684/9915?subId1=A2ASUBID',
          encryptedTrackingLink: 'SmJHamhjWWJYR1NSOUduMVdXbVo5bmJaY0pOVDFHV0xMeTJOWlp5c21ZYWNUTUlodWtEUlFaQ29iZFNKVWMya1FhNTJrMzFZaTMzMUxrVVJZV0VkSFIyY0xsYTFSblhsVzJiaFJsRmFoNFdGblYzMkxZPWl4YjA0MDJYTHhZRDExbE5kbG1HTG8zbjlUbFV5YXAyMWJGMmt6YWNoazUzem8weHpWTDFiTU5RMlhGanloYmRNWXRSQjBKU1lMbFp2NWRja2VSRm5iWDRVajNwa1ZSV1Z6RzNTOXp0UVNjRWVDMDBjc0wybXRSMGNTeWpKMkpNWnloV21ROXUxeXRka01XRXkydEpaZ1VNVFMza3VUTzBWbFRwbDBualVXT040dzFGWVdUOXRuek9Oenl5Y3Y5d1k1WTBsVzFGVzFHTlpHTFYxZGtZMGkxZGtOVkRabGVNMWFuaEw-01',
          id: '2868'
        },
        {
          logo: '$/images/home/Retailers/21_Booking_RetailerHomePage.png',
          link: 'https://www.dpbolvw.net/click-8242621-11795693?sid=A2ASUBID',
          encryptedTrackingLink: 'am1HRVVreGRseFk5ZGtoand0RHpVM0VrekpZc1FEUkR2YzBkTU9ibDFSTVdrcGpkYnVjVHoyRjBVUU1MMlpPWk5OdzRkYzBtVE1iei9kMXpqcHpDbFFFTVlpeGN5PUJNeDBoV05RMEdOOFVZNUR6aUNsNlo9VlVPWml5Mw--01',
          id: '2692'
        }
      ],
      [
        {
          logo: '$/images/home/Retailers/14_B_Q_RetailerHomePage.png',
          link: 'https://www.awin1.com/cread.php?awinmid=483&awinaffid=272501&clickref=A2ASUBID',
          encryptedTrackingLink: 'RWNjRlprRllRT3ZtWVZUR0pYRmlwY0ZORDJXV2IwbGJ0WW1FUXprUWdrbWFQTUwzMzRSUlRXVEVkWXd6Y1lQSGRXTXpaM1pkamltbE9kalowVUxSaUxob0VFbE49WVh4Yjgyak5oWk1ZRU11d2RUa0owSFcwMHkxNXcyalphajJkc1ZtV3kxVXo5bjUyZ0JDanIyeg--01',
          id: '2758'
        },
        {
          logo: '$/images/home/Retailers/18_PLT_RetailerHomePage.png',
          link: 'https://www.awin1.com/cread.php?awinmid=5577&awinaffid=272501&clickref=A2ASUBID',
          encryptedTrackingLink: 'UjZkall5OVc4TVlNVHp5bmNNWjFJYTl5V2xiemRQVG9CQVZOVlVUZFpVaT1FcmpZbnZ3ek1Zb2xRWldXMFF5VzVEM1JiRlhaUlhENTF6UjJkalFZRVkyVVgyeFJOMTBhb0RIVVpNQ3llYkpoOWx6NEZGZDlXeTNUaU1UaVUzWDluWkZhZEp5bWNCd1lqU0pvZHpVM1d2UG41eDFCSnVuRzBkWE0wZFJaUW1FWFdiTDFHbVdsTmJXVWlwdUhqT0hkV0JKVnlOUm1BbHRIY0d1TlloZEpOR214UzBhTjMzZFVTMVprWlFaR09kd1VwNVltdVhaWndXa2k-01',
          id: '2620'
        },
        {
          logo: '$/images/home/Retailers/22_TESCO_RetailerHomePage.png',
          link: 'https://www.awin1.com/cread.php?awinmid=7052&awinaffid=272501&clickref=A2ASUBID',
          encryptedTrackingLink: 'dmt0bk5vTWhOTTBOd0ltT1JQWHZVVW1RUm16UlpOUFhXTXViaDBhTUpNWGw5Wm1CWmJMWWFiNE5GY2xOa3hsUUdZQUF3Vm5OSThIM0drWkczL0NjMllXbnZUYU5kakR2VG1oMk1qWjBGZFdwbFdUM2NNVWFaek5tWjBWMWp4eU5keno0TE1UaFdiMERPQlJqY1pYalJ1bEpOMj11TXlrVEhsSmxpd2JYYmJaa21UbEpORVpKMG55MWNRY3pXd0lXNGM4ellORmRRMDNaaXZSYjNhYlVWWHlRPVVnSk9VWVk1V0dFU1N6b3d1MHpabVRjRlcybHZUV0pQRVdH01',
          id: '2644'
        }
      ],
      [
        {
          logo: '$/images/home/Retailers/15_MyProtein_RetailerHomePage.png',
          link: 'https://www.awin1.com/cread.php?awinmid=3196&awinaffid=272501&clickref=A2ASUBID',
          encryptedTrackingLink: 'bUdZMlZZTllWbW5jOU8yYTBpQ2cyZ1pGbU56WkpteEdRMU56VDJNeFFqRFFEYk9oamxSNXBiPTVVM1dqPXlaelkxTWt2V1pSSE5VZHRWTm1Za05CSmNpdmxabHd5OGg0eD1BVzBVbVR3a1BZNWtZcDNPU3pjVkJRZDlPejBkU01kZHhkM1lheXBFTVBKam45RUw0VXRXRWg-01',
          id: '2639'
        },
        {
          logo: '$/images/home/Retailers/19_CurrysRetailerHomePage.png',
          link: 'https://www.awin1.com/cread.php?awinmid=1599&awinaffid=272501&clickref=A2ASUBID',
          encryptedTrackingLink: 'TmR5WkdjZHhOUVR0aDJOVEpYY3kxWmN6NT1HbTFFZGNNakphWjFISFVkNXRyQnRzWVRaWWFjYmE1bGhiZHlUMmlXajFqRElVZ0dZVllXdU9Mb1JSQ0YweVhXTFRobGRwSmw1TGRITDNtMTRKV1RPbXV6QjA1ZFdSYUREYXpaWDlvZGRvMndiWGpRTlVRTEdoa3k1bllKNVZueE5tcFp3OWtkWXoyVlJTMmlqbDJUWVlUa2EyZFcxVVcxY2wxdWRyUm9ZeGtUZE1rQVdXdWlNWFZpU05SWXlwd1gyWnhZa1ZKeXpMWUYxV2loeFQwQk16MHZ5Sm1oMWhTMWRpV2wyaFozUGNKcDltPWFkT2R6RGpYSFoyV1RsWVpXYkExMGp3MjFNSnhablhXdzlZRUNkY0pNdFJ6ZE5FVGtkaWpSQmQ9WkdXeldFOWNCaG01d3BtQ3I0ellzMGROWVl6VmFNVFdoZEMxVUdMR0JXNVdwYVF0T05tMDJ6V1YxOTM5WTJGNW1vWk96UlNNYmRRR2hMdzJNR1RaVnpMUWhkdDMxV0x5NEF5VE1DWjVaTm0-01',
          id: '2649'
        },
        {
          logo: '$/images/home/Retailers/20_boohoo_RetailerHomePage.png',
          link: 'https://www.awin1.com/cread.php?awinmid=2319&awinaffid=272501&clickref=A2ASUBID',
          encryptedTrackingLink: 'R0NrWlRseXdRNENFWWQ5bXhZcE5EYVhRVDMxalQ9NWMyV21PV01IUFIwaFZZaWpZNFFZUWtUM1pWWnRCTmJUMmNPVUZ2Rj00cFdkY2h3Q2F1bGo1WjNjeE5tV3ptTlRqPTVha3lZMHBZakp6dllqYVVselJXZ0xoZERkWW1RRWRQMHhKMjZkWUU5MFl6M3ZOTUVWV013TlU-01',
          id: '2651'
        }
      ],
      [
        {
          logo: '$/images/home/Retailers/16_JD_RetailerHomePage.png',
          link: 'https://www.awin1.com/cread.php?awinmid=1431&awinaffid=272501&clickref=A2ASUBID',
          encryptedTrackingLink: 'NGlySVdSTVpra1c0d1h3VnVNalRGWVdXYkpVSnpqejJKU1RhYzBUY1FZcWl5em0zWmFJYVdabU1KUjNXUGIzVUNqVkJKUjBOeDVIR3UwdWxYY3N3akxZWXhwTVIzbXgxODlXWk5ZeWp0RDBXaFo1SWpkTmtwQUdOTXVaWU5ZaU1ETmNwVFoyM2xXTklkRmRIQm56aGJXUk5yUXpQaDBDTUpsSnlKalIxVUwzYllZWDJZOXZ6SnpSa2x3RTNrWnhZVUpseU5sM3dKUj1Va2NkVU9oeVpZRVlTVVNOVXpqeWFkeU9jVkpRTmNWdmRFQlZiWEpaV2kxWjM-01',
          id: '2742'
        },
        {
          logo: '$/images/home/Retailers/20_easyJeat_RetailerHomePage.png',
          link: 'https://www.awin1.com/cread.php?awinmid=20401&awinaffid=272501&clickref=A2ASUBID',
          encryptedTrackingLink: 'WHQwR1dOOVRkUnl3Umx3eHBPWjA1MllVdmxVVENrT1pHdm1RUVlKbnlYa2N6bHgwRFZ1NTUyMUxZNFZobEZNbVhEcGNNUkVtV2h6Wk1hTUpZbWxSVnlqNEphUG5TRm0yTXV1VT1STEZwQWRjdU5id05NakU5WVFNRlRSaFBoa25MU3EzcFdOZFlVM0pyWmJDWGxpeEdjMDNNMVlGVWRZZENYTW1pM05UWkRSakRXbE5VZHBabDNsWk90QlA5WVRSUmNXbXovbE5kRVpZYlNvTWpQd2preUdjRnlYWQ--01',
          id: '4834'
        },
        {
          logo: '$/images/home/Retailers/24_Footlocker_RetailerHomePage.png',
          link: 'https://www.awin1.com/cread.php?awinmid=15594&awinaffid=272501&clickref=A2ASUBID',
          encryptedTrackingLink: 'a1F5ZGpXT3pNY3RsZGNERHlkbmtGZFpOZHZETkZqRU5tTlRsUjJCaG9kVW1sPTJobDJWWlRaRkpNaE0wd2RCbFp2TmFISlZZTmFKTmxTeDROR1MyaGFsQlgxbHozSnBXYmI0eTVNa3lOc1dXRGtkalZhY2Mybk5aVU0zU1c1Y2hOclgyRlRhaFRSeTl4cENMbkJtTWIxbU51WVA4bzF1SXlhZW11UmtaVFJZejRYYzRNVlVXbWpqeVRZbWN1d2pFWmFqUVUxek1ZWnczQ1lZMTZaZFhjRzNsMWJTYU1RemJkcjJaWFZXUVNHQnV6TXZ6V0VkWU1rV2JNY1pSTnR6bVpNOEhZWEpZVlhaMGJ5dVRBWGlNa0ZHR2N0VU1UMTJqRzB5TjVHVXlWQnBTWVkzR0d4bVo4aGRjRjBadWNPWnRrSlZGUWdPVXlWTVIxRVd0ZGtKTkNqVEZrbFhHMjVtbGsxY1lYbVZra0ptWFQxa1I-01',
          id: '3073'
        }
      ]
    ],
    accountLoginUrl: config.accountLoginUrl,
    accountJoinUrl: config.accountJoinUrl
  }),

  computed: {
    ...mapState({
      featuredRetailers: state => state.featuredRetailers
    }),

    ...mapGetters({
      featuredPublications: 'featuredPublications'
    }),

    redirect () {
      return crossDeviceSupport.mobileOs() ? '/desktop-only' : '/unsupported-browser'
    },

    browser () {
      // reffering to this issue https://stackoverflow.com/a/67009825/5158372
      const userAgent = window.navigator.userAgent
      const vendor = window.navigator.vendor
      switch (true) {
        case /Edge|Edg|EdgiOS/.test(userAgent):
          return 'to Edge'
        case /Chrome/.test(userAgent) && vendor === GOOGLE_VENDOR_NAME && isChromium():
          return 'to Chrome'
        case /Firefox|FxiOS/.test(userAgent):
          return 'to Firefox'
        default:
          return ''
      }
    }
  },

  methods: {
    async emailRegistration (data) {
      if (!data.Email || !data.Email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)) {
        return Promise.reject({ code: 'InvalidParameterException' })
      }

      if (!data.ReceiveInfo && !data.ReceiveNewsletter) {
        return Promise.reject({ code: 'InvalidParameterException' })
      }

      /**
       * Subscribes to Onboarding
       */
      if (data.ReceiveInfo) {
        const mailchimpTemplateOnboarding = this.$mailchimp.getTemplate(data.Email, 'mobile_onboarding')
        this.$store.dispatch('postDataToMailchimp', mailchimpTemplateOnboarding).catch(err => console.log(err))
        this.$messages.success(this.$t('home.congratulations-on-sign-up'), '', true)
      }

      /**
       * Subscribes to the Newsletter
       */
      if (data.ReceiveNewsletter) {
        const mailchimpTemplateNewsletter = this.$mailchimp.getTemplate(data.Email, 'mobile_newsletter')
        this.$store.dispatch('postDataToMailchimp', mailchimpTemplateNewsletter).catch(err => console.log(err))
        this.$messages.success(this.$t('home.congratulations-newsletter'), '', true)
      }

      this.$router.push(this.redirect)
    },

    openPropositionModal (part) {
      this.propositionModalActive = true
      this.propositionModalPart = part
    },

    toggleConsentInfo (event) {
      this.consentInfoActive = !!event.target.value
    },
    buildClickoutLink
  }
}
</script>

<style lang="sass" scoped>
.main-layout
  background-color: #fbf7f7
  width: 100vw
  overflow: hidden
.container-laptop
  padding: 0
  @media screen and ( min-width: 1024px )
    display: flex
    justify-content: flex-end
  img
    @media screen and ( max-width: 768px )
      max-width: 70vw
      margin-bottom: 32px
      margin-left: auto
      margin-right: auto
      display: block
    @media screen and ( min-width: 1024px )
      max-width: 710px
#hero
  background-color: #FDFBFB
  width: 100vw
  padding-bottom: 220px
  position: relative
  overflow: unset
  @media screen and ( min-width: 1024px )
    padding-left: 0
    padding-right: 0
    padding-bottom: 0
    padding-top: 45px
  .container
    @media screen and ( min-width: 1024px )
      top: 60px

  .info-wrap
    @media screen and ( min-width: 1024px )
      max-width: 540px
    .add-button
      @media screen and ( min-width: 1024px )
        margin-bottom: 16px
  .title
    @media screen and ( min-width: 1024px )
      font-size: 52px !important
  + .bg-image
      overflow: hidden
      display: flex
      align-items: center
      justify-content: center
      transform: scale(2)
      @media screen and ( min-width: 1024px )
        transform: unset
      img
        width: 100%
        object-fit: cover

.rating-container
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center

  @media screen and ( min-width: 1024px )
    justify-content: flex-start

  &__info
    font-size: 12px
    margin-left: 4px
    font-weight: 400
    color: #363636
    @media screen and ( min-width: 1024px )
      font-size: 16px
      margin-left: 12px
    b
      font-weight: 700
.cards-section
  display: flex
  flex-direction: column
  justify-content: space-between
  margin-top: -270px
  @media screen and ( min-width: 1024px )
    margin-top: 0

.intro
  max-width: 609px
  margin: 0 auto
  text-align: inherit
  &__subtitle
    font-size: 16px
    line-height: 18px
    letter-spacing: 2px
    color: #FF6441
    margin-bottom: 16px
    display: block
    text-align: inherit
  &__title
    font-weight: 600
    font-size: 24px
    line-height: 135%
    color: #363636
    display: block
    margin: 0 auto 16px auto
    text-align: inherit
    max-width: 474px
    @media screen and ( min-width: 1024px )
      font-size: 32px
  &__desc
    font-size: 16px
    line-height: 150%
    letter-spacing: 0.16px
    color: #7A7A7A
    margin-bottom: 24px
    text-align: inherit
    &--bold
      font-weight: 600

.how-it-works
  + .bg-image
    img
      display: block

.retailers
  padding: 50px 24px
  text-align: center
  position: relative
  margin: 0
  background-color: #fdfbfb
  @media screen and ( min-width: 1024px )
    padding: 80px 24px 20px
  + .bg-image
    img
      display: block

  &__list
    max-width: 875px
    margin: 0 auto
  &__card
    background: #FFFFFF
    border-radius: 8px
    margin-left: 8px
    margin-right: 8px
    margin-bottom: 16px
    height: auto
    min-width: 135px
    box-shadow: 0px 2px 4px rgba(54, 54, 54, 0.08)
    display: flex
    align-items: center
    justify-content: center
    @media screen and ( min-width: 1024px )
      margin-bottom: 24px
      margin-left: 14px
      margin-right: 14px
.reward
  padding: 35px 24px
  text-align: center
  max-width: 1440px
  margin: 0 auto
  position: relative
  overflow: unset
  background-color: #fbf7f7
  + .bg-image
    img
      display: block
  @media screen and ( min-width: 768px )
    display: flex
    align-items: center
    text-align: left
  @media screen and ( min-width: 1024px )
    padding-top: 40px
    padding-bottom: 50px
  .intro
    &__title
      margin-left: auto
      margin-right: auto
      width: 70vw
      @media screen and ( min-width: 768px )
        margin-left: 0
    &__desc
      @media screen and ( min-width: 768px )
        margin-left: 0
        margin-right: 0
  .columns
     @media screen and ( min-width: 1440px )
       align-items: center
  &__img
    margin: 0 auto 24px auto
    img
      max-width: 360px
      width: 100%
      @media screen and ( min-width: 1440px )
        max-width: 500px
  &__cta
    text-align: inherit
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    @media screen and ( min-width: 768px )
      flex-direction: row
      justify-content: flex-start
      align-items: flex-start
    .button
      border-radius: 8px
      margin-bottom: 24px
      max-width: 180px
      &:last-child
        margin-bottom: 0
      @media screen and ( min-width: 768px )
        margin-bottom: 0
        margin-right: 24px
        &:last-child
          margin-right: 0
    &--only-desktop
      @media screen and ( max-width: 767px )
        display: none
    &--only-mobile
      @media screen and ( min-width: 768px )
        display: none
  &__learn-more-button
    border: 2px solid #FF6441
    border-radius: 8px
#press
  background-color: #FDFBFB
  margin-bottom: 0
  margin-top: 0
  padding-top: 60px
  + .bg-image
    img
      display: block
  .intro__subtitle
      margin-bottom: 30px !important
#testimonials
  margin-top: 30px
  margin-bottom: 55px
  @media screen and ( min-width: 1366px )
    margin-bottom: 90px
  ::v-deep .VueCarousel
    @media screen and ( min-width: 1366px )
      padding-bottom: 60px
  ::v-deep .VueCarousel-pagination
    margin-left: -56px
    margin-right: -56px
    width: unset
    @media screen and ( min-width: 1366px )
      max-width: unset
      margin-left: unset
      left: 0
      right: 0
      bottom: 0
      position: absolute
      width: unset
.add-pouch
  display: flex
  flex-direction: column
  justify-content: center
  margin-bottom: 55px
  @media screen and ( min-width: 1024px )
    margin-top: 0
    margin-bottom: 80px

  &__header
    margin-bottom: 24px
    display: flex
    flex-direction: column
    @media screen and ( min-width: 1024px )
      margin-bottom: 32px
  &__title
    font-weight: 700
    font-size: 32px
    @media screen and ( min-width: 1024px )
      font-size: 52px
  &__subtitle
    font-weight: 300 !important
    font-size: 32px
    @media screen and ( min-width: 1024px )
      font-size: 52px
  .add-button
    @media screen and ( min-width: 1024px )
      margin-bottom: 32px
  .rating-container
    justify-content: center
.shape
  position: absolute
  background-image: url('/static/images/home/orange-shape.svg')
  background-size: contain
  background-repeat: no-repeat

  &--1
    width: 70vw
    height: 70vw
    top: -40px
    left: -100px
    transform: rotate(215deg)
    @media screen and ( min-width: 1024px )
      width: 414px
      height: 414px
      left: unset
      right: -80px
      transform: rotate(275deg)
      top: -100px
  &--2
    width: 40vw
    height: 40vw
    right: -70px
    top: 40vh
    @media screen and ( min-width: 1024px )
      width: 4vw
      height: 4vw
      right: 10vw
      top: 395px
.bg-image
  img
    width: 100%
.add-button
  border-radius: 8px
  margin-bottom: 24px
  background-color: #FF6441
  border-radius: 8px
  display: inline-block
  font-weight: 600
  font-size: 16px
  color: #ffffff
  padding: 0 48px
  line-height: 48px
  height: unset
</style>
