<template>
  <div>
    <div class="cards" v-only-desktop>
      <div class="card" @click="flipCard('card1')">
        <div :class="{ flip: card1 }" class="card-inner">
          <div class="card-front">
            <div class="card-image">
              <img v-lazy="`$/images/never_overpay_cilp.svg`" alt="Collect Pouch Points" :class="card1 ? 'fade-leave-active' : 'fade-enter-active'" />
            </div>
            <div class="card-description">
              <b class="has-text-primary has-text-weight-semibold">Collect Pouch Points</b>
              <span class="has-text-weight-normal">and redeem them.</span>
            </div>
          </div>
          <div class="card-back">
            <span>
              <a href="/points">Pouch Points</a> are rewards we give you when you shop online using Pouch at participating retailers, as well as
              completing certain tasks with us and entering fun competitions.
            </span>
          </div>
        </div>
      </div>

      <div class="card" @click="flipCard('card2')">
        <div :class="{ flip: card2 }" class="card-inner">
          <div class="card-front">
            <div class="card-image">
              <img v-lazy="`$/images/data_protection_cilp.svg`" alt="Your data is safe" :class="card2 ? 'fade-leave-active' : 'fade-enter-active'" />
            </div>
            <div class="card-description">
              <div>
                <b class="has-text-primary has-text-weight-semibold">Your data</b>
                <br />
                <span class="has-text-weight-normal"> is safe with us.</span>
              </div>
            </div>
          </div>
          <div class="card-back">
            <span>
              We take data protection very seriously. We’ve even written
              <a target="_blank" href="https://joinpouch.com/en-gb/blog/how-pouch-looks-after-your-data">an article </a> on the topic... To summarise,
              we do not capture any personal data.
            </span>
          </div>
        </div>
      </div>

      <div class="card" @click="flipCard('card3')">
        <div :class="{ flip: card3 }" class="card-inner">
          <div class="card-front">
            <div class="card-image">
              <img v-lazy="`$/images/its_free_cilp.svg`" alt="Pouch is free to install" :class="card3 ? 'fade-leave-active' : 'fade-enter-active'" />
            </div>
            <div class="card-description">
              <span class="has-text-weight-normal">Pouch is free to</span>
              <b class="has-text-primary has-text-weight-semibold">install and to use.</b>
            </div>
          </div>
          <div class="card-back">
            <span>
              Our extension is <em>100% free</em>, just add it to your browser and start shopping. At checkout, Pouch will let you know how many codes
              are available and apply them to your online shopping.
            </span>
          </div>
        </div>
      </div>

      <div class="card" @click="flipCard('card4')">
        <div :class="{ flip: card4 }" class="card-inner">
          <div class="card-front">
            <span class="card-badge has-text-primary has-text-weight-light" :class="card4 ? 'fade-leave-active' : 'fade-enter-active'">NEW!</span>
            <div class="card-image">
              <img
                v-lazy="`$/images/favourite-brands.svg`"
                alt="Never miss a deal from your Favourite Brands!"
                :class="card4 ? 'fade-leave-active' : 'fade-enter-active'"
              />
            </div>
            <div class="card-description">
              <span class="has-text-weight-normal">Never miss a deal from</span>
              &nbsp;
              <span class="has-text-weight-normal">your <b class="has-text-primary has-text-weight-semibold">Favourite Brands!</b></span>
            </div>
          </div>
          <div class="card-back">
            <span>
              We instantly notify you whenever one of your <a target="_blank" href="https://www.deals.joinpouch.com/post/favourite-brands-is-now-live-never-miss-another-deal-you-want ">Favourite Brands</a> has a new offer. Discover and add your favourite brands now.
            </span>
          </div>
        </div>
      </div>
    </div>

    <carousel
      :loop="true"
      :perPage="1"
      :autoplay="false"
      :pagination-padding="5"
      :paginationActiveColor="$config.style.primaryColor"
      style="width: 100%;"
      class="cards"
      v-only-mobile
    >
      <slide>
        <div class="card" @click="flipCard('card1')">
          <div :class="{ flip: card1 }" class="card-inner">
            <div class="card-front">
              <div class="card-image">
                <img
                  v-lazy="`$/images/never_overpay_cilp.svg`"
                  alt="Collect Pouch Points"
                  :class="card1 ? 'fade-leave-active' : 'fade-enter-active'"
                />
              </div>
              <div class="card-description">
                <b class="has-text-primary has-text-weight-semibold">Collect Pouch Points</b>
                <span class="has-text-weight-normal">and redeem them.</span>
              </div>
            </div>
            <div class="card-back">
              <span>
                <a href="/points">Pouch Points</a> are rewards we give you when you shop online using Pouch at participating retailers, as well as
                completing certain tasks with us and entering fun competitions.
              </span>
            </div>
          </div>
        </div>
      </slide>

      <slide>
        <div class="card" @click="flipCard('card2')">
          <div :class="{ flip: card2 }" class="card-inner">
            <div class="card-front">
              <div class="card-image">
                <img
                  v-lazy="`$/images/data_protection_cilp.svg`"
                  alt="Your data is safe"
                  :class="card2 ? 'fade-leave-active' : 'fade-enter-active'"
                />
              </div>
              <div class="card-description">
                <div>
                  <b class="has-text-primary has-text-weight-semibold">Your data</b>
                  <br />
                  <span class="has-text-weight-normal"> is safe with us.</span>
                </div>
              </div>
            </div>
            <div class="card-back">
              <span>
                We take data protection very seriously. We’ve even written
                <a target="_blank" href="https://joinpouch.com/en-gb/blog/how-pouch-looks-after-your-data">an article </a> on the topic... To
                summarise, we do not capture any personal data.
              </span>
            </div>
          </div>
        </div>
      </slide>

      <slide>
        <div class="card" @click="flipCard('card3')">
          <div :class="{ flip: card3 }" class="card-inner">
            <div class="card-front">
              <div class="card-image">
                <img
                  v-lazy="`$/images/its_free_cilp.svg`"
                  alt="Pouch is free to install"
                  :class="card3 ? 'fade-leave-active' : 'fade-enter-active'"
                />
              </div>
              <div class="card-description">
                <span class="has-text-weight-normal">Pouch is free to</span>
                <b class="has-text-primary has-text-weight-semibold">install and to use.</b>
              </div>
            </div>
            <div class="card-back">
              <span>
                Our extension is <em>100% free</em>, just add it to your browser and start shopping. At checkout, Pouch will let you know how many
                codes are available and apply them to your online shopping.
              </span>
            </div>
          </div>
        </div>
      </slide>

      <slide>
        <div class="card" @click="flipCard('card4')">
          <div :class="{ flip: card4 }" class="card-inner">
            <div class="card-front">
              <span class="card-badge has-text-primary has-text-weight-light" :class="card4 ? 'fade-leave-active' : 'fade-enter-active'">NEW!</span>
              <div class="card-image">
                <img
                  v-lazy="`$/images/favourite-brands.svg`"
                  alt="Never miss a deal from your Favourite Brands!"
                  :class="card4 ? 'fade-leave-active' : 'fade-enter-active'"
                />
              </div>
              <div class="card-description">
                <span class="has-text-weight-normal">Never miss a deal from</span>
              &nbsp;
              <span class="has-text-weight-normal">your <b class="has-text-primary has-text-weight-semibold">Favourite Brands!</b></span>
              </div>
            </div>
            <div class="card-back">
              <span>
                We instantly notify you whenever one of your <a target="_blank" href="https://www.deals.joinpouch.com/post/favourite-brands-is-now-live-never-miss-another-deal-you-want ">Favourite Brands</a> has a new offer. Discover and add your favourite brands now.
              </span>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  components: { Carousel, Slide },
  data () {
    return {
      card1: false,
      card2: false,
      card3: false,
      card4: false
    }
  },

  methods: {
    flipCard (card) {
      this.$data[card] = !this.$data[card]
    }
  }
}
</script>

<style lang="sass" scoped>

.cards
  display: flex
  flex-flow: row wrap
  align-items: center
  justify-content: center
  margin-top: 24px
  @media screen and ( min-width: 1024px )
    margin-top: 0
  .card
    margin: 11.75px 11.75px
    // width: 270px
    // height: 280px
    background-color: transparent
    box-shadow: none
    cursor: pointer
    perspective: 1000px

    @media screen and ( max-width: 768px )
      width: 100%
      margin-left: 0
      margin-right: 0

    .flip
      transform: rotateY(-180deg)
    .flop
      transform: rotateY(180deg)
    .card-inner
      display: block
      width: 270px !important
      height: 280px
      transition: transform 0.8s
      border-radius: 6.7px
      box-shadow: 0 0 4px 0 rgba(61, 61, 61, 0.2)
      transform-style: preserve-3d
      margin-left: auto
      margin-right: auto
      @media screen and ( max-width: 768px )
        width: 100%

      &:hover
        box-shadow: 0 0 4px 0 rgba(61, 61, 61, 0.8)
      .card-front
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        border-radius: 8px
        background-color: white
        padding: 24px
        position: relative

        .card-badge
          position: absolute
          top: 24px
          right: 24px
          transition: 0.8s

        .card-image
          width: 110px
          height: 110px
          img
            width: 100%

        .card-description
          display: flex
          flex-direction: row
          margin-top: 18px
          font-size: 19.5px
          font-weight: 500
          align-items: center
          flex-wrap: wrap
          text-align: center
          justify-content: center
      .card-back
        display: flex
        justify-content: center
        align-items: center
        border-radius: 6.7px
        background-color: white
        padding: 0 30px
        font-size: 15px
        font-weight: 300
        transform: rotateY(180deg)
        text-align: center
        .link
          &:hover
            text-decoration: underline
      .card-front, .card-back
        position: absolute
        -webkit-backface-visibility: hidden /* Safari */
        backface-visibility: hidden
        width: 100%
        height: 100%
</style>
