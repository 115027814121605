<template>
  <div class="how-it-works">
    <div class="how-it-works__container">
      <div class="how-it-works__desc">
        <div class="intro">
          <span class="intro__subtitle">STEP BY STEP</span>
          <h2 class="intro__title has-text-weight-bold">How easy it works.</h2>
        </div>
        <div class="how-it-works__steps">
          <div
            class="how-it-works__step"
            :class="{ 'how-it-works__step--active': activeStep === i }"
            v-for="(step, i) in steps"
            :key="i"
            @click="activeStep = i"
          >
            <h3 class="how-it-works__point">
              <span class="how-it-works__point--brand">{{ step.number }}.</span>
              {{ step.point }}
            </h3>
            <p class="how-it-works__details">{{ step.details }}</p>
          </div>
        </div>
      </div>

      <div class="how-it-works__slides">
        <carousel
          :perPage="1"
          paginationColor="#DDDDDD"
          paginationActiveColor="#FF6441"
          :value="activeStep"
          @page-change="handlePageChange"
          :autoplay="false"
          :loop="true"
        >
          <slide v-for="(step, i) in steps" :key="i">
            <div class="how-it-works__slide">
              <video class="how-it-works__video" autoplay muted @ended="vidoeEnded" :ref="`videoElement-${i}`">
                <source v-src="step.video" type="video/mp4" />
              </video>

              <div class="how-it-works__bottom-info">
                <h3 class="how-it-works__point">
                  <span class="how-it-works__point--brand">{{ step.number }}.</span>
                  {{ step.point }}
                </h3>
                <p class="how-it-works__details">{{ step.details }}</p>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
const isFirefox = /Firefox|FxiOS/.test(window.navigator.userAgent)
export default {
  data: () => ({
    steps: [
      {
        number: '01',
        point: 'Pin Pouch to your browser.',
        details: 'Click the "Grey Puzzle" icon on the top right of your browser toolbar, find Pouch and click the "Pin" icon.',
        video: isFirefox ? '$/videos/en/HowItWorks_Firefox_Step01.mp4' : '$/videos/en/HowitWorks_Step01.mp4'
      },
      {
        number: '02',
        point: 'Click the Pouch icon.',
        details: 'Visit your favourite retailers and click the Pouch icon to see all the available codes and deals.',
        video: '$/videos/en/HowitWorks_Step02.mp4'
      },
      {
        number: '03',
        point: 'We make it easier for you.',
        details: 'If prompted at checkout, click "Try Codes" to apply all codes in just one click.',
        video: '$/videos/en/HowitWorks_Step03.mp4'
      }
    ],
    activeStep: 0
  }),
  methods: {
    vidoeEnded (e) {
      this.activeStep = this.activeStep + 1 > this.steps.length - 1 ? 0 : this.activeStep + 1
    },
    handlePageChange (number) {
      this.$refs[`videoElement-${this.activeStep}`][0].currentTime = 0
      this.activeStep = number
    }
  }
}
</script>

<style lang="sass" scoped>
.how-it-works
  text-align: center
  padding: 0 20px
  margin: 0
  background-color: #FBF7F7
  position: relative
  &__container
    max-width: 1440px
    margin: 0 auto
    ::v-deep .VueCarousel-dot-container
      margin-top: 0 !important
    .intro__title
      margin-bottom: 30px
    @media screen and ( min-width: 1024px )
      text-align: left
      display: grid
      grid-template-columns: 1fr 1fr
      ::v-deep .VueCarousel-pagination
        display: none
      .intro
        margin: 0 0 24px 0
        &__title
          margin: 0
  &__desc
    display: flex
    flex-direction: column
    justify-content: center
    background-color: #fbf7f7

    @media screen and ( min-width: 1024px )
      flex: 1 0 50%
      margin-left: 16px
      order: 1

  &__steps
    display: none
    cursor: pointer
    position: relative
    @media screen and ( min-width: 1024px )
      display: block

      &:before
        content: ""
        width: 1px
        position: absolute
        top: 0
        bottom: 0
        left: 0
        background-color: rgba(54, 54, 54, 0.5)

  &__step
    padding-left: 60px
    max-width: 580px
    opacity: 0.5
    border-left: 3px solid transparent
    transition: all 0.5s
    margin-bottom: 24px
    &:last-child
      margin-bottom: 0
    &--active
      opacity: 1
      border-left-color: #FF6441
      position: relative
      @media screen and ( min-width: 1024px )
        .how-it-works__point--brand
          color: #FF6441
  &__slides
  &__slide
  &__img
    margin: 0 auto 24px auto
    max-width: 616px
    background-color: #fbf7f7
  &__video
   object-fit: cover
   display: block
   margin-left: auto
   margin-right: auto
   margin-bottom: 24px
   background-color: #fbf7f7

   @media screen and ( min-width: 1024px )
      max-height: 450px
      margin-bottom: 0

  &__bottom-info
    @media screen and ( min-width: 1024px )
      display: none
  &__point
    font-weight: 700
    font-size: 16px
    line-height: 24px
    letter-spacing: 0.16px
    margin-bottom: 12px
    color: #7A7A7A
    position: relative
    @media screen and ( min-width: 1024px )
      margin-bottom: 8px
    &--brand
      color: #FF6441
      @media screen and ( min-width: 1024px )
        color: #7A7A7A
        position: absolute
        left: -32px
  &__details
    font-size: 16px
    line-height: 150%
    color: #7A7A7A
.intro
  max-width: 609px
  margin: 0 auto
  text-align: inherit
  &__subtitle
    font-size: 16px
    line-height: 18px
    letter-spacing: 2px
    color: #FF6441
    margin-bottom: 16px
    display: block
    text-align: inherit
  &__title
    font-weight: 600
    font-size: 24px
    line-height: 135%
    color: #363636
    display: block
    margin: 0 auto 16px auto
    text-align: inherit
    max-width: 474px
    @media screen and ( min-width: 1024px )
      font-size: 32px
    &--brand
      color: #FF6441
  &__desc
    font-size: 16px
    line-height: 150%
    letter-spacing: 0.16px
    color: #7A7A7A
    margin-bottom: 24px
    text-align: inherit
    &--bold
      font-weight: 600
</style>
